<template>
    <v-footer color="grey lighten-4" padless>
        <v-container class="py-16">
            <v-row>
                <v-col class="d-flex justify-center" cols="12">
                    <router-link to="/">
                        <v-img
                            :src="require('@/assets/logo.png')"
                            alt="Job Hot Shot"
                            contain
                            height="60"
                        />
                    </router-link>
                </v-col>

                <v-col class="d-flex flex-wrap justify-center" cols="12">
                    <v-btn to="/policy" plain x-small> Privacy Policy </v-btn>

                    <v-btn to="/contact" plain x-small> Contact Us </v-btn>

                    <v-btn to="/policy#ca" plain x-small>
                        CA - Do Not Sell My Personal Information
                    </v-btn>
                </v-col>

                <v-col>
                    <div class="text-caption text-center">
                        © {{ new Date().getFullYear() }} Job Hot Shot.
                        <strong>All rights reserved</strong>.
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-footer>
</template>

<script>
export default {
    name: "Footer",
    data: () => ({
        //
    }),
};
</script>